import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'gatsby';
import { Input } from 'reactstrap';
import searchIcon from "../assets/images/search-icon.png";
import "../assets/css/shopify-header.css"

const ShopifyHeader = ({ reforgedMenu = null, isShopifyList = false, setSearchInput, isLongWebAddress = null, sectionThemeObj }) => {
    return (
        <header className={`header-common ${isLongWebAddress ? "shopify-full-page-header" : "shopify-sticky-header"}`} >
            <Container fluid className='shopify-header-container'>
                {isShopifyList || isLongWebAddress && reforgedMenu?.images?.length ?
                    <Row className={`main-bar ${isLongWebAddress && reforgedMenu ? "logo-active" : "logo-inactive"}`}>
                    {isLongWebAddress && reforgedMenu?.images?.length ?
                            <Col xs={6} xl={4} lg={6} md={6} sm={4} className='pb-0 logo header-logo' >
                                <Link to={'/'}><img src={reforgedMenu?.images?.[0]?.url} alt="logo" /></Link>
                            </Col> : ""}
                        {isShopifyList && <Col xs={6} xl={3} lg={4} md={4} sm={6} className='p-0'>
                            <div className='input-search-box'>
                                <Input className="input-search" onChange={(e) => setSearchInput(e.target.value)} placeholder="Search" />
                                <img src={searchIcon} width={20} height={20} className='search-icon' />
                            </div>
                        </Col>}
                    </Row> : ""}
            </Container>
            <div>
                {reforgedMenu ?
                    <nav className="top-nav-container" style={{ background: sectionThemeObj?.headerBgColor || "" }}>
                        <ul className='top-nav-component'>
                            {reforgedMenu?.lists?.length && reforgedMenu?.lists?.map((menu, index) =>
                                <li className='top-nav-item' key={index}>
                                    <Link to={menu?.path} className='nav-link-item' style={{ color: sectionThemeObj?.menuTextColor || "" }}>{menu?.name}</Link>
                                </li>
                            )}

                        </ul>
                    </nav> : ""}
            </div>
        </header>
    );
}

export default ShopifyHeader;
